// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-gatunki-stali-index-js": () => import("./../../src/pages/gatunki-stali/index.js" /* webpackChunkName: "component---src-pages-gatunki-stali-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-kontakt-thanks-js": () => import("./../../src/pages/kontakt/thanks.js" /* webpackChunkName: "component---src-pages-kontakt-thanks-js" */),
  "component---src-pages-odkuwki-swobodnie-kute-index-js": () => import("./../../src/pages/odkuwki-swobodnie-kute/index.js" /* webpackChunkName: "component---src-pages-odkuwki-swobodnie-kute-index-js" */),
  "component---src-pages-realizacje-index-js": () => import("./../../src/pages/realizacje/index.js" /* webpackChunkName: "component---src-pages-realizacje-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gatunki-stali-post-js": () => import("./../../src/templates/gatunki-stali-post.js" /* webpackChunkName: "component---src-templates-gatunki-stali-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-odkuwki-ksztaltowe-swobodnie-kute-post-js": () => import("./../../src/templates/odkuwki-ksztaltowe-swobodnie-kute-post.js" /* webpackChunkName: "component---src-templates-odkuwki-ksztaltowe-swobodnie-kute-post-js" */),
  "component---src-templates-odkuwki-swobodnie-kute-post-js": () => import("./../../src/templates/odkuwki-swobodnie-kute-post.js" /* webpackChunkName: "component---src-templates-odkuwki-swobodnie-kute-post-js" */),
  "component---src-templates-products-page-js": () => import("./../../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-realizacje-post-js": () => import("./../../src/templates/realizacje-post.js" /* webpackChunkName: "component---src-templates-realizacje-post-js" */),
  "component---src-templates-tags-js": () => import("./../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

